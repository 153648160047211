<template>
  <div>
    <div class="title">数据字典</div>
    <div class="right" style="margin-bottom: 2vh">
      <a-button type="primary" @click="add">新建</a-button>
    </div>

    <a-table
      :dataSource="dataDict"
      :loading="loading"
      :pagination="false"
      rowKey="id"
    >
      <a-table-column key="name" title="键" data-index="name"></a-table-column>
      <a-table-column
        key="value"
        title="值"
        data-index="value"
      ></a-table-column>
      <a-table-column key="icon" title="图标">
        <template slot-scope="text">
          <div v-if="text.icon">{{ text.icon }}</div>
          <div v-else>--</div>
        </template>
      </a-table-column>
      <a-table-column key="sort" title="排序">
        <template slot-scope="text">
          <div v-if="typeof text.sort === 'number'">
            {{ text.sort }}
          </div>
          <div v-else>--</div>
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a href="#" @click.prevent="edit(text)">编辑</a>
            <a
              href="#"
              @click.prevent="deleteText(text)"
              class="danger"
              v-if="
                (text.children && text.children.length === 0) || !text.children
              "
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <!-- <Edit :visible="visible" @cancel="cancel" :text="editingText" /> -->
  </div>
</template>

<script>
import { remove } from "@/api/setting/data-dict";
import { mapActions, mapState } from "vuex";
// import Edit from "./edit.vue";
export default {
  components: {
    // Edit,
  },

  data() {
    return {
      loading: false,
      rawList: [], // 原始的，未结构化的数据

      visible: false,
      editingText: {
        name: "",
        id: "",
        value: "",
      },
    };
  },

  computed: {
    ...mapState("setting", ["dataDict"]),
  },

  mounted() {
    this.getList();
  },

  methods: {
    ...mapActions("setting", ["getDataDict"]),

    getList() {
      this.loading = true;

      this.getDataDict().finally(() => {
        this.loading = false;
      });
    },

    add() {
      this.editingText = {};
      this.visible = true;
    },

    edit(text) {
      this.editingText = text;
      this.visible = true;
    },

    cancel() {
      this.visible = false;
      this.getList();
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>
